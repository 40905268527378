import {eventQBus} from "../utils/EventQBus";

const FEATURE_CLASS = "js_find_compare";
const FLAG_CLASS = "js_find_compareInput";

/**
 *
 *
 *
 */
function interceptSubmit(event: Event) {
  if (event.defaultPrevented) {
    return;
  }

  eventQBus.emit("ftfind.comparison.start", {
    san_ComparisonStartType: "link",
  });

  /*                         */
  event.preventDefault();
}

/**
 *
 */
function toggleParticipation(variationId: string, target: HTMLInputElement | null) {
  const topic = target?.checked ? "ftfind.tile.compare" : "ftfind.tile.uncompare";
  eventQBus.emit(topic, {
    source: "box",
    variationId: variationId,
  });
}

function onTileCompareStateChanged(variationId: string, compared: boolean) {
  document
    .querySelectorAll<HTMLInputElement>(`.${FEATURE_CLASS}[data-variation-id='${variationId}'] .${FLAG_CLASS}`)
    .forEach((element) => (element.checked = compared));
}

function initCompare(target: HTMLElement, variationId: string) {
  const formElement = target.querySelector("form");
  if (formElement) {
    formElement.addEventListener("submit", interceptSubmit);
  }
  const compareCheckboxSelector = `#find_compare_${variationId}`;
  const compareCheckbox = target.querySelector(compareCheckboxSelector);
  if (compareCheckbox && compareCheckbox instanceof HTMLInputElement) {
    /*                                                         */
    compareCheckbox.addEventListener("change", (_) => toggleParticipation(variationId, compareCheckbox));
  }
}

function initAllTiles() {
  Array.from(document.getElementsByClassName(FEATURE_CLASS)).forEach((elem: Element) => {
    if (elem instanceof HTMLElement && elem.dataset.variationId) {
      initCompare(elem, elem.dataset.variationId);
    }
  });
}

export default function registerCompare() {
  eventQBus.on("ftfind.tile.loaded", (event) => initCompare(event.target, event.variationId));
  eventQBus.on("ftfind.tilelist.restored", () => initAllTiles());
  eventQBus.on(`ftfind.tile.compared`, ({ variationId }) => onTileCompareStateChanged(variationId, true));
  eventQBus.on(`ftfind.tile.uncompared`, ({ variationId }) => onTileCompareStateChanged(variationId, false));
}
