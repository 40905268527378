import { eventQBus } from "../utils/EventQBus";

export const BENEFIT_CLASS = "js_reptile_discountBenefit";
const TILE_CLASS = "find_tile";

function triggerSheetListener(tile: HTMLElement, benefit: HTMLElement) {
  eventQBus.emit("reptile.kuvo-sheet.open", {
    target: tile,
    variationId: tile.dataset.variationId,
    benefitId: benefit.dataset.benefitId,
  });
}

export function initDiscountBenefit(target: HTMLElement) {
  const tileElement = target as HTMLElement;
  const benefitElementCollection = Array.from(
    target.getElementsByClassName(BENEFIT_CLASS) as HTMLCollectionOf<HTMLElement>,
  );
  benefitElementCollection.forEach((benefitElement: HTMLElement) => {
    benefitElement.addEventListener("click", () => triggerSheetListener(tileElement, benefitElement));
  });
}

export function initAllTiles() {
  Array.from(document.getElementsByClassName(TILE_CLASS)).forEach((elem: Element) => {
    if (elem instanceof HTMLElement && elem.dataset.variationId) {
      initDiscountBenefit(elem);
    }
  });
}

export default function registerDiscountBenefit() {
  eventQBus.on("ftfind.tile.loaded", (event) => initDiscountBenefit(event.target));
  eventQBus.on("ftfind.tilelist.restored", () => initAllTiles());
}
