import { eventQBus } from "../utils/EventQBus";
import { OnTileLoad } from "../types/Tile";

const MAX_RETRIES = 2;

/**
 *
 *
 *
 *
 *
 */
function extractHtmlFragment(xhr: Response) {
  if (xhr.status !== 200 && xhr.status !== 404) {
    throw Error(`Failed to retrieve tile: ${xhr.status} ${xhr.statusText}`);
  }
  return xhr.text();
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
function insertTile(this: HTMLElement, html: string) {
  const range = document.createRange();
  /*                                 */
  /*                                                               */
  range.selectNodeContents(this);
  const fragment = range.createContextualFragment(html);

  /*           */
  range.deleteContents();
  range.insertNode(fragment);

  /*                   */
  this.classList.remove("find_tile--lazy", "js_reptile_tile--lazy");
  this.removeAttribute("data-retry-count");
}

/**
 *
 */
function onLoadTile(event: OnTileLoad, retryCount = 0): Promise<never> | Promise<void> {
  const tileTarget = event.target;

  if (tileTarget) {
    const href = tileTarget.dataset["href"];

    if (href) {
      const tracking = ""; /*                                   */
      return fetch(href, {
        headers: {
          /*                                                                                     */
          /*                                                                                             */
          /*                                                                               */
          "X-Find-Trackable": tracking,
        },
      })
        .then(extractHtmlFragment)
        .then(insertTile.bind(tileTarget))
        .catch(() => {
          if (retryCount < MAX_RETRIES) {
            return onLoadTile(event, retryCount + 1);
          }
        });
    }
  }
  return Promise.reject();
}

export default function registerLazyTile() {
  eventQBus.on("ftfind.tile.load", onLoadTile);
}
