import { eventQBus } from "../utils/EventQBus";
import { OnTileLoaded } from "../types/Tile";

function initTooltipForTile(event: OnTileLoaded) {
  eventQBus.emit("assets.tooltip.init", `li[data-variation-id='${event.variationId}']`);
}

export default function registerTooltipInitializer() {
  eventQBus.on("ftfind.tile.loaded", initTooltipForTile);
}
