import { eventQBus } from "../utils/EventQBus";
import { isBackNavigation } from "../utils/Navigation";

const PRODUCT_CLASS = "product";
const TILE_CLASS = "find_tile";
const WISHLIST_BUTTON = "js_reptile_addToWishlist";
const ANIMATE_CLASS = "reptile_wishlistButton--animate";

function handleWishlistButtonClick(event: Event) {
  const target = event.target as Element;
  const btn = target?.closest(`.${WISHLIST_BUTTON}`) as HTMLElement;
  const tile = btn?.closest(`.${TILE_CLASS}`) as HTMLElement;
  const productTile = tile?.closest(`.${PRODUCT_CLASS}`) as HTMLElement;

  if (btn && tile && productTile) {
    const variationId = tile.dataset.variationId || "";
    const qualified = tile.dataset.qualified === "true";
    const addedFrom = productTile.dataset.productListingType || "";

    addToWishlist(variationId, qualified, addedFrom);
    showMessage();

    if (!btn.classList.contains(ANIMATE_CLASS)) {
      /*               */
      btn.classList.add(ANIMATE_CLASS);

      /*                                        */
      setTimeout(() => {
        btn.classList.remove(ANIMATE_CLASS);
      }, 1000);
    }
  }

  event.preventDefault();
  event.stopPropagation();
}

function addToWishlist(variationId: string, qualified: boolean, addedFrom: string) {
  eventQBus.emit("ft1.wishlist.add", {
    variationId,
    qualified,
    addedFrom,
  });
}

function showMessage() {
  eventQBus.emit("assets.snackbar.trigger", {
    text: "Gespeichert!",
    actionLinkText: "Merkzettel anzeigen",
    actionFunction: () => {
      eventQBus.emit("tracking.bct.trackOnNextPageImpression", { san_Interaction: "wishlist_banner" });
      window.location.href = "/wishlist-view/";
    },
  });
}

function registerListener(target: HTMLElement) {
  const heartElement: HTMLElement = target.querySelector(`.${WISHLIST_BUTTON}`) as HTMLElement;

  if (heartElement) {
    heartElement.addEventListener("click", handleWishlistButtonClick);
  }
}

export function initAllTiles() {
  Array.from(document.querySelectorAll(`.${TILE_CLASS}:not(${TILE_CLASS}--lazy)`)).forEach((elem: Element) => {
    if (elem instanceof HTMLElement) {
      registerListener(elem);
    }
  });
}

export default function registerWishlistButton() {
  eventQBus.on("ftfind.tile.loaded", (event) => registerListener(event.target));
  /*               */
  eventQBus.on("ftfind.tilelist.restored", () => {
    if (isBackNavigation()) {
      initAllTiles();
    }
  });
}
