import { eventQBus } from "../utils/EventQBus";

export function registerTileNameMaxHeightFix() {
  const lineHeight = getLineHeight();
  eventQBus.on("ftfind.tile.loaded", ({ target }) => {
    if (target && lineHeight) {
      const tileName = target.querySelector<HTMLElement>(".find_tile__name");
      if (tileName) {
        /*                                                                */
        /*                                                                                                         */
        tileName.style.setProperty("--line-height", lineHeight);
      }
    }
  });
}

function getLineHeight() {
  const element = document.createElement("span");
  element.style.cssText = "display:inline-block; padding:0; position:absolute; visibility:hidden;";
  element.classList.add("pl_copy100"); /*                                       */
  element.appendChild(document.createTextNode("dummy"));
  document.body.append(element);

  const lineHeight = window.getComputedStyle(element).getPropertyValue("height");
  element.remove();

  return lineHeight;
}
