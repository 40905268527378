const TOGGLE_NAMES = ["DUMMY"] as const;
const CROCOTILE_TOGGLES = "crocotile_toggles";

export type ToggleName = (typeof TOGGLE_NAMES)[number];
export type ToggleStates = Record<ToggleName, boolean>;

let toggles: ToggleStates;

function asBoolean(value?: string): boolean {
  return value && JSON.parse(value);
}

export function initToggles() {
  const data = document.getElementById(CROCOTILE_TOGGLES)?.dataset;
  const state = data ? (toggleName: ToggleName) => asBoolean(data[toggleName]) : () => false;
  toggles = TOGGLE_NAMES.reduce((htmlToggles, toggleName) => {
    htmlToggles[toggleName] = state(toggleName);
    return htmlToggles;
  }, {} as Partial<ToggleStates>) as ToggleStates;
}

export function isActive(toggle: ToggleName) {
  return toggles[toggle];
}
