import { eventQBus } from "../utils/EventQBus";
import { OnSimilarProductButtonOpen, OnTileLoaded } from "../types/Tile";
import { SimilarProductsButtonAnimation } from "./SimilarProductsButtonAnimation";

export enum CLASS {
  SIMILAR_PRODUCTS_CONTAINER = "js_reptile_tile__similarProducts",
  SIMILAR_PRODUCTS_BUTTON = "js_reptile_tile__similarProductsButton",
  BRAND_IN_NAME = "find_tile__brandInName",
  BRAND = "find_tile__brand",
  PRODUCT_LINK = "find_tile__productLink",
  IMAGE_LINK = "js_find_tile__productImageLink",
  RETAIL_PRICE = "find_tile__retailPrice ",
  PRICE = "find_tile__priceValue",
}

export class SimilarProductsButton {
  private animation: SimilarProductsButtonAnimation | null;

  private constructor(
    public target: HTMLElement,
    private variationId: string,
  ) {
    this.container?.addEventListener("click", (event) => this.onClick(event));
    this.animation = SimilarProductsButtonAnimation.create(this.container);
  }

  static create(target: HTMLElement, variationId: string) {
    return new SimilarProductsButton(target, variationId);
  }

  static createAll() {
    Array.from(document.getElementsByClassName("find_tile")).forEach((elem: Element) => {
      if (elem instanceof HTMLElement && elem.dataset.variationId) {
        SimilarProductsButton.create(elem, elem.dataset.variationId);
      }
    });
  }

  close() {
    this.container?.removeEventListener("click", (event) => this.onClick(event));
    this.animation?.close();
  }

  get picture(): HTMLPictureElement | null {
    const imageLinkUrl = this.target.getElementsByClassName(CLASS.IMAGE_LINK);
    if (!imageLinkUrl || imageLinkUrl.length === 0) return null;
    const picture = imageLinkUrl[0].getElementsByTagName("picture");
    if (!picture || picture.length === 0) return null;
    return picture[0] as HTMLPictureElement;
  }

  get container(): HTMLDivElement {
    const container = this.target.querySelector(`.${CLASS.SIMILAR_PRODUCTS_CONTAINER}`) as HTMLDivElement;
    if (!container) return document.createElement("div");
    return container;
  }

  get title(): string {
    const title =
      this.target.getElementsByClassName(CLASS.BRAND_IN_NAME) || this.target.getElementsByClassName(CLASS.BRAND);
    if (!title || title.length === 0) return "";
    return (title[0].textContent || "").trim();
  }

  get imageWebpUrl(): string {
    const sources = this.picture?.getElementsByTagName("source");
    if (!sources || sources.length === 0) return "";
    for (const source in sources) {
      if (sources[source].getAttribute("type") === "image/webp") return sources[source].srcset;
    }
    return "";
  }

  get imageJpegUrl(): string {
    const sources = this.picture?.getElementsByTagName("source");
    if (!sources || sources.length === 0) return "";
    for (const source in sources) {
      if (sources[source].getAttribute("type") === "image/jpeg") return sources[source].srcset;
    }
    return "";
  }

  get imageUrl(): string {
    const img = this.picture?.getElementsByTagName("img");
    if (!img || img.length === 0) return "";
    return (img[0] as HTMLImageElement).src;
  }

  get imageAltTag(): string {
    const img = this.picture?.getElementsByTagName("img");
    if (!img || img.length === 0) return "";
    return (img[0] as HTMLImageElement).alt;
  }

  get adsLink(): string {
    const adsLink = this.target.getElementsByClassName(CLASS.PRODUCT_LINK);
    if (!adsLink || adsLink.length === 0) return "";
    return (adsLink[0] as HTMLAnchorElement).getAttribute("href") || "";
  }

  get price(): string {
    const price = this.target.getElementsByClassName(`${CLASS.RETAIL_PRICE} ${CLASS.PRICE}`);
    if (!price || price.length === 0) return "";
    return (price[0] as HTMLElement).textContent || "";
  }

  get openResponse(): OnSimilarProductButtonOpen {
    return {
      title: this.title,
      imageSourceJpegUrl: this.imageJpegUrl,
      imageSourceWebpUrl: this.imageWebpUrl,
      imageUrl: this.imageUrl,
      imageAltTag: this.imageAltTag,
      adsLink: this.adsLink,
      price: this.price,
      variationId: this.variationId,
    };
  }

  private onClick(eventClick: MouseEvent) {
    eventClick.preventDefault();
    eventQBus.emit("reptile.tile.similarProductsOpen", this.openResponse);
  }
}

export default function registerSimilarProductButton() {
  eventQBus.on("ftfind.tile.loaded", (event: OnTileLoaded) =>
    SimilarProductsButton.create(event.target, event.variationId),
  );
  eventQBus.on("ftfind.tilelist.restored", () => SimilarProductsButton.createAll());
}
