const deprecatedTypeReference: Record<number, string> = { 0: "navigate", 1: "reload", 2: "back_forward" };

/**
 *
 *
 *
 *
 *
 *
 */
function isNavigationType(expectedType: "navigate" | "reload" | "back_forward"): boolean {
  if (!window.performance) {
    return false;
  }

  if (window.performance.getEntriesByType) {
    const performanceEntries: PerformanceEntryList = window.performance.getEntriesByType("navigation");
    if (performanceEntries.length > 0 && "initiatorType" in performanceEntries[0]) {
      const pnt: PerformanceNavigationTiming = performanceEntries[0] as PerformanceNavigationTiming;
      return pnt.initiatorType === "navigation" && pnt.type === expectedType;
    }
  } else if (window.performance.navigation) {
    const type = window.performance.navigation.type;

    return deprecatedTypeReference[type] === expectedType;
  }

  return false;
}

export function isPageReload(): boolean {
  return isNavigationType("reload");
}

export function isBackNavigation(): boolean {
  return isNavigationType("back_forward");
}
