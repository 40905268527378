export const SIMILAR_PRODUCTS_ICON_TAG = "oc-icon-v1";

export enum KEYFRAME {
  TEXT_FADEIN = "reptile-sp-text-fadein",
}

export enum CLASS {
  BIG = "big-mode",
  SMALL = "small-mode",
}

export class SimilarProductsButtonAnimation {
  private hoverActive: boolean = false;
  private initialEvent: boolean = true;

  private constructor(public container: HTMLDivElement) {
    this.container.addEventListener("animationstart", this.onStart.bind(this));
    this.container.addEventListener("animationcancel", this.onCancel.bind(this));
    this.container.addEventListener("animationend", this.onEnd.bind(this));
    this.container.addEventListener("mouseenter", this.setBigMode.bind(this));
    this.container.addEventListener("mouseleave", this.setSmallMode.bind(this));
  }

  static create(container: HTMLDivElement | null) {
    if (!container) return null;
    return new SimilarProductsButtonAnimation(container);
  }

  close() {
    this.container.removeEventListener("animationstart", this.onStart.bind(this));
    this.container.removeEventListener("animationcancel", this.onCancel.bind(this));
    this.container.removeEventListener("animationend", this.onEnd.bind(this));
    this.container.removeEventListener("mouseenter", this.setBigMode.bind(this));
    this.container.removeEventListener("mouseleave", this.setSmallMode.bind(this));
  }

  get icon(): HTMLElement | null {
    const icon = this.container.getElementsByTagName(SIMILAR_PRODUCTS_ICON_TAG);
    if (!icon || icon.length === 0) return null;
    return icon[0] as HTMLElement;
  }

  get button(): HTMLButtonElement | null {
    const button = this.container.querySelector(".reptile_tile__similarProductsButton");
    if (!button) return null;
    return button as HTMLButtonElement;
  }

  setIconSize(size: string, time: number = 100) {
    setTimeout(() => this.icon?.setAttribute("size", size), time);
  }

  private onStart(event: AnimationEvent) {
    if (event.animationName != KEYFRAME.TEXT_FADEIN) return;
    this.hoverActive = false;

    if (this.initialEvent) {
      this.setIconSize("100", 150);
      this.initialEvent = false;
    }
  }

  private onCancel(event: AnimationEvent) {
    this.hoverActive = true;
  }

  private onEnd(event: AnimationEvent) {
    if (event.animationName != KEYFRAME.TEXT_FADEIN) return;
    this.hoverActive = true;
  }

  private setSmallMode() {
    if (!this.hoverActive) return;
    this.button?.classList.remove(CLASS.BIG);
    this.button?.classList.add(CLASS.SMALL);
    this.setIconSize("100");
  }

  private setBigMode() {
    if (!this.hoverActive) return;
    this.button?.classList.remove(CLASS.SMALL);
    this.button?.classList.add(CLASS.BIG);
    this.setIconSize("50");
  }
}
